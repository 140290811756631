<template>
    <div class="eimageselect-wrapper d-flex flex-row flex-wrap justify-space-around ga-2">
        <div
            :class="'image d-flex flex-row justify-content-center align-content-center ' + additionalClasses(imageIndex)"
            :id="'eimageselect-' + imageIndex"
            v-for="(image, imageIndex) in images"
            :key="imageIndex"
            @click="() => selectImage(imageIndex)"
        >
            <img :src="image.thumbnail" />
        </div>
    </div>
</template>

<script type="text/javascript">

export default {
    name: "EImageSelect",
    props: {
        images: { type: Array, default: () => []}
    },
    data() {
        return {
            index: null
        }
    },
    methods: {
        selectImage(imageIndex){
            this.index = imageIndex
            this.$emit("imageSelected", imageIndex)
        },
        additionalClasses(imageIndex) {
            if (imageIndex == this.index) return "selected"
            return ""
        }
    },
}
</script>